import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_MODAL_POPUP } from "../../../redux/actionTypes/common.actionTypes";
import { FormattedMessage } from "react-intl";

const PopupModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { error, message, show, callbackurl } = useSelector(
    ({ CommonReducer }) => CommonReducer
  );
  const handleClose = () => {
    dispatch({ type: HIDE_MODAL_POPUP });
    if (callbackurl) {
      router.push(callbackurl);
    }
  };
  return (
    <>
      <Modal dialogClassName="regi_modal" show={show} centered>
        <Modal.Body>
          <div className="RegiModalBody">
            <Image
              src={`/assets/images/${!error ? "check" : "error"}-icon.png`}
              width="50"
              height="50"
              alt="icon"
            />
            <br />
            <h4>{message}</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleClose}>
            {" "}
            <FormattedMessage id="close" defaultMessage="बंद करें" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopupModal;
